export default async ({ $api, $bus, from, route, store, redirect }) => {
    /* const permitted = await store.dispatch('onPage', route);

    if(!permitted) {
        await store.dispatch('onPage', { path: '/' });
        redirect('/');
    } */

    await $api.auth.page({ path: route.fullPath }).then(response => {
        console.log(response);
        /* const { permitted, widgets } = response.content;

        !permitted && redirect('/');

        store.commit('SET_WIDGETS', widgets);
        console.log('ON PAGE:', response) */
    });
}