import Vue from 'vue'

export const state = () => ({
    database: void 0,
    entities: {},
    account: void 0
});

export const mutations = {
    SET_ACCOUNT(state, account) {
        if(state.account && state.account.id !== account.id) {
            state.entities = {};
        }

        state.account = account;
    },

    SET_DATABASE(state, database) {
        state.database = database;
    },

    SET_ENTITIES(state, { group, data }) {
        Vue.set(state.entities, group, data);
    },

    SET_META(state, payload) {
        const { database, file_id, stage, data } = payload;
        //debugger
        for(const key in state.entities[database]) {
            const entity = state.entities[database][key];

            if(entity.id === file_id) {
                entity.meta = { ...entity.meta, stage, data };
                break;
            }
        }
    },

    DELETE_FILE(state, { id }) {
        const key = state.entities[state.database].findIndex(value => value.id === id);
        debugger;
        Vue.delete(state.entities[state.database], key);
    },
};

export const getters = {
    /* isLoading(state) {
        return state.loading !== 0;
    } */
}

export const actions = {
    async setDatabase({ commit, dispatch, state }, database) {
        const { content = [] } = database ? await this.$api.fetch.files({ database }, { silent: true }) : {};

        commit('SET_ENTITIES', { group: database, data: content });

        commit('SET_DATABASE', database);
    },

    async deleteFile({ commit, dispatch, state }, { id }) {
        const { content = [] } = await this.$api.fetch.file.delete({ database: state.database, id }, { silent: true });

        commit('DELETE_FILE', { id });
    },

    async nuxtClientInit({ commit, dispatch, state }, { app, req, $bus }) {
    },

    async nuxtServerInit ({ commit, dispatch, state }, { req }) {
    }
};

export const strict = true;