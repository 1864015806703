
export default async (context, inject) => {
    //debugger
    if(process.browser) {
        const sse = new EventSource(`/api/gateway._sse_?channel=broadcast`);

        sse.addEventListener(`${context.$config.namespace}:broadcast:event`, (e) => {
            context.store.commit('SET_META', JSON.parse(e.data));
            //sse.close();
        });
        
        context.$sse = {};

        inject('sse', {});
    }
}