import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  DataGrid: () => import('../../components/data-grid.vue' /* webpackChunkName: "components/data-grid" */).then(c => wrapFunctional(c.default || c)),
  Files: () => import('../../components/files.vue' /* webpackChunkName: "components/files" */).then(c => wrapFunctional(c.default || c)),
  Signin: () => import('../../components/signin.vue' /* webpackChunkName: "components/signin" */).then(c => wrapFunctional(c.default || c)),
  Upload: () => import('../../components/upload.vue' /* webpackChunkName: "components/upload" */).then(c => wrapFunctional(c.default || c)),
  SystemLoading: () => import('../../components/system/loading.vue' /* webpackChunkName: "components/system-loading" */).then(c => wrapFunctional(c.default || c)),
  SystemModal: () => import('../../components/system/modal.vue' /* webpackChunkName: "components/system-modal" */).then(c => wrapFunctional(c.default || c)),
  TemplateButtons: () => import('../../components/template/buttons.vue' /* webpackChunkName: "components/template-buttons" */).then(c => wrapFunctional(c.default || c)),
  TemplateControl: () => import('../../components/template/control.vue' /* webpackChunkName: "components/template-control" */).then(c => wrapFunctional(c.default || c)),
  TemplateDatesHeader: () => import('../../components/template/dates-header.vue' /* webpackChunkName: "components/template-dates-header" */).then(c => wrapFunctional(c.default || c)),
  TemplateDates: () => import('../../components/template/dates.vue' /* webpackChunkName: "components/template-dates" */).then(c => wrapFunctional(c.default || c)),
  TemplateFileName: () => import('../../components/template/file-name.vue' /* webpackChunkName: "components/template-file-name" */).then(c => wrapFunctional(c.default || c)),
  TemplateStage: () => import('../../components/template/stage.vue' /* webpackChunkName: "components/template-stage" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
