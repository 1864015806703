import json5 from 'json5';
import moment from 'moment-timezone';
moment.locale('ru');
process.env.TIMEZONE && moment.tz.setDefault(process.env.TIMEZONE);

export default async (context, inject) => {
    let { req, res, $config, store, route, $loading, $axios } = context;

    let cookie = void 0;

    /* if(!process.browser) {
        cookie = req.headers.cookie;
    } */

    const baseURL = process.browser ? `${location.origin}/api` : process.env.API_PROXY || `http://127.0.0.1:${process.env.NUXT_PORT}/api`

    const request = $axios.create({
        baseURL,
        /* proxy: {
            host: '127.0.0.1',
            port: 8301
        } */
    });


    let { data: api } = await request.get('/gateway._api_');

    const call = async function (endpoint, payload = {}, options = {}) {
        if(typeof(payload) !== 'object') {
            throw { code: '400', message: 'Payload should be an object.' };
        }

        let data = void 0;

        !options.silent && $loading.start();

        try {
            await request({
                url: `/${endpoint}`,
                method: 'POST',
                data: payload,
                ...options,
                /* headers: {
                    ...(cookie ? { cookie } : void 0)
                } */
            })
            .then(response => {
                data = response.data;
            })
            .catch(err => {
                data = err.response ? { error: err.response.data.error || err.response.data } : { error: err };

                console.log(`DATA ERROR, ${JSON.stringify(data, 0, 2)}`);
            });
        }
        catch(error) {
            data = { error };

            console.log(`DATA ERROR, ${JSON.stringify(data, 0, 2)}`);
        }

        console.log(`ACTION CALLED ON ${process.browser ? "client" : "server"} SIDE:`, endpoint);

        const { account } = data;

        account && store.commit('SET_ACCOUNT', account);

        $loading.stop();

        return data;
    };

    const help = api.reduce((memo, endpoint) => {
        let [group, action] = endpoint.name.split(/\./);

        if(action) {
            memo[group] = memo[group] || {};
            memo[group][action] = memo[group][action] || { ...endpoint };
        }
        else {
            memo[group] = memo[group] || { ...endpoint };
        }

        return memo;
    },{});

    api = api.reduce((memo, endpoint) => {
        const request = async (...args) => {

            return call(endpoint.name, ...args);
        };

        let [service, ...rest] = endpoint.name.split(/\./);

        memo[service] = memo[service] || {};

        let end = memo[service];

        rest.forEach((item, inx, arr) => {
            if(end[item] && inx === arr.length - 1) {
                const tail = end[item];
                end[item] = request;

                for(let key in tail) {
                    end[item][key] = tail[key];
                }
            }

            end[item] = end[item] || (inx === arr.length - 1 ? request : {});

            end = end[item];
        });

        return memo;
    }, {});

    api.$help = help;

    api.$json = {
        parse: (text) => new Promise((resolve, reject) => {
            try {
                resolve(json5.parse(text));
            }
            catch(err) {
                reject(err);
            }
        }),

        stringify: (object) => new Promise((resolve, reject) => {
            try {
                resolve(json5.stringify(object));
            }
            catch(err) {
                reject(err);
            }
        })
    };

    api.$moment = moment;

    context.$api = api;
    inject('api', api);
}
