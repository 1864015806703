
import Vue from 'vue';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '@/assets/dx.css';

import DxForm from 'devextreme-vue/form';
import DxDataGrid from 'devextreme-vue/data-grid';
import { DxTreeList } from 'devextreme-vue/tree-list';
import DxTagBox from 'devextreme-vue/tag-box';
import DxLookup from 'devextreme-vue/lookup';

Vue.component('dx-form', DxForm);
Vue.component('dx-data-grid', DxDataGrid);
Vue.component('dx-tree-list', DxTreeList);
