//
//
//
//
//
//

export default {
    data: () => ({
        loadingCount: 0,
        timer: void 0
    }),
    methods: {
        start (immidiate) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.loadingCount++, immidiate ? 0 : 300);
        },
        finish (abort) {
            clearTimeout(this.timer);
            abort ? this.loadingCount = 0 : this.loadingCount--;
            this.loadingCount < 0 && (this.loadingCount = 0);
        },
        fail() {
            this.loadingCount = 0;
        }
    },
    computed: {
        loading() {
            return this.loadingCount > 0
        }
    }
}
