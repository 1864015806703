const start = (immediate) => {
    if(process.browser && window.$nuxt) {
        const { $loading } = window.$nuxt.$root;

        $loading && $loading.start && $loading.start(immediate);
    }
}

const stop = (abort) => {
    
    if(process.browser && window.$nuxt) {
        const { $loading } = window.$nuxt.$root;

        $loading && $loading.finish && $loading.finish(abort);
    }
}

export default async (context, inject) => {
    let loading = {
        start,
        stop
    };

    context.$loading = loading;
    inject('startLoading', loading);
}